.full-height {
    height: 100vh;
}

.flex-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

.content {
    text-align: center;
}

.title {
    font-size: 84px;
}

.links>a {
    color: #636b6f;
    padding: 0 25px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
}

.m-b-md {
    margin-bottom: 30px;
}


/*my code starts*/

$poppins: "Poppins",
sans-serif;

/*variable ends*/

a,
a:hover {
    text-decoration: none;
}

body {
    padding-bottom: 50px;
}

.theme--light.v-btn.v-btn--icon {
    color: #ffffff;
}

th {
    color: #3B3D3F !important;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    position: relative;
    .down_arrow {
        margin-left: 5px;
        i {
            color: #1C22FF;
        }
    }
    .ellipsis {
        position: absolute;
        right: 20px;
        font-size: 20px;
        width: 20px;
    }
}

.v-slide-group__wrapper {
    border: 2px solid #E7EDF1;
}

.v-list-group__header.v-list-item.v-list-item--link.theme--dark:hover {
    background: #1E4C8F;
}

.v-card__title,
.scope_title {
    font-family: $poppins;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    text-transform: capitalize;
}

.scope_title {
    padding-bottom: 16px;
}


/*form design starts*/

.form-control {
    margin-bottom: 32px;
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #1F70EA;
    outline: none;
}

select {
    font-family: $poppins !important;
    font-size: 14px;
    color: #4D5052 !important;
    height: 48px !important;
    min-width: 298px;
    position: relative;
}

.select_icon {
    &::after {
        font-family: "FontAwesome";
        content: "\f107";
        right: 12px;
        top: 11px;
        font-size: 18px;
        color: #757575;
        position: absolute;
    }
}

.search_icon {
    input {
        padding-right: 35px;
    }
}

.search_icon::after {
    font-family: "FontAwesome";
    content: "\f002";
    right: 12px;
    top: 0px;
    font-size: 18px;
    position: absolute;
    color: #dadada;
}

.form-group {
    position: relative;
    margin-bottom: 0px !important;
}

input:focus-visible {
    outline: none;
}

.form-group {
    position: relative;
}

.form-control {
    border: none;
    padding: 0px;
    color: rgba(177, 178, 179, 1);
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
    border-radius: 0px;
}

.form-group {
    position: relative !important;
    input,
    textarea {
        width: 100%;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, .87);
        background: transparent;
        padding: 5px 0px;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        opacity: 0.8;
        border-radius: 0px;
    }
    textarea:focus {
        outline: none;
    }
    label {
        font-family: $poppins;
        font-size: 14px;
        color: #A3A9AD;
        position: absolute;
        top: 4px;
        left: 0px;
        -webkit-transition: .5s;
        -o-transition: .5s;
        transition: .5s;
        pointer-events: none;
    }
    .select_label {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #B1B2B3;
        opacity: 0.8;
        position: absolute;
        top: -15px;
    }
    input:focus~label,
    textarea:focus~label,
    input:valid~label,
    textarea:valid~label {
        top: -15px;
        font-size: 12px;
        color: #1976d2;
    }
}


/*form design ends*/

.fixed_button {
    background: #ffffff;
    -webkit-box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.15);
    box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.15);
    min-height: 72px;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #eaeaea;
    z-index: 4;
    .button_group {
        padding: 12px;
        button {
            text-transform: uppercase;
            margin-left: 12px;
        }
    }
}

.notranslate {
    &.mdi {
        &.mdi-menu {
            &.theme--light {
                color: #606366;
            }
        }
    }
}

.v-toolbar__title {
    color: #606366;
}

.v-toolbar__content {
    background: #ffffff;
    border: 2px solid #F3F3F3;
}

.v-sheet {
    &.v-app-bar {
        &.v-toolbar:not(.v-sheet--outlined) {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }
    }
}


/*toolbar design css ends*/

.v-navigation-drawer__content {
    background: #232E3E;
}

.v-data-table>.v-data-table__wrapper>table {
    font-family: $poppins;
    font-size: 14px;
    line-height: 20px;
    color: #4D5052;
}

.v-list-item__title,
.v-card__title,
.v-label,
.v-data-footer,
.v-textarea textarea,
.v-btn__content,
.v-input input,
.v-list-item__content,
.v-select__selections,
.v-messages__message,
.editor-label,
.v-toolbar__title,
.v-card__title,
.scope_title {
    font-family: $poppins;
}

.v-application .headline,
.v-application .title {
    line-height: 2rem;
    font-family: $poppins;
}


/*button css starts*/

button {
    outline: none !important;
}

.v-application .blue-grey {
    background-color: #1F70EA !important;
    border-color: #1F70EA !important;
}

.blue_btn {
    font-family: $poppins;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    background: #1F70EA;
    color: #ffffff !important;
    border-radius: 4px;
    padding: 14px 25px;
    outline: none;
}

.skyblue_btn {
    font-family: $poppins;
    font-weight: 600;
    color: #1F70EA !important;
    font-size: 14px;
    line-height: 20px;
    background: #DBE9FF;
    border-radius: 4px;
    padding: 14px 25px;
    outline: none;
}

.outline_btn {
    font-family: $poppins;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    outline: none;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 10px 21px;
    color: #1F70EA !important;
    border: 2px solid #1F70EA;
}

.discurd_btn {
    font-family: $poppins;
    font-weight: 600;
    padding: 14px 25px;
    color: #1F70EA !important;
    background: #ffffff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    outline: none;
}

.theme--light {
    &.v-size--large {
        &.blue-grey {
            font-family: $poppins;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            background: #1F70EA !important;
            border: 2px solid #1F70EA !important;
            border-radius: 4px;
            letter-spacing: 1px;
            color: #FFFFFF;
        }
    }
}


/*blue-btn css ends*/

.v-btn--has-bg {
    &.theme--light {
        &.v-size--default {
            background: #ffffff !important;
            color: #1F70EA !important;
            border: 2px solid #1F70EA !important;
            border-radius: 4px;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
}


/*white-btn css ends*/

.theme--light {
    &.v-size--default {
        &.blue--text {
            &.text--darken-1 {
                color: #ffffff !important;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                padding: 14px 44px;
                background: #1F70EA;
                border-radius: 4px;
            }
        }
    }
}


/*Modal Save button ends*/

.v-btn--disabled {
    &.v-btn--text {
        &.theme--light {
            &.v-size--default {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 1px;
                color: #1F70EA !important;
            }
        }
    }
}


/*Modal close button ends*/

.table th,
.table td {
    vertical-align: middle;
}

.v-tab {
    font-family: $poppins;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px !important;
    text-transform: uppercase;
    color: #A3A9AD !important;
    &.v-tab--active {
        color: #232E3E !important;
    }
}

.v-input input,
.v-list-item__content {
    font-size: 14px;
    line-height: 20px;
}

.list {
    padding-left: 0px !important;
    margin-bottom: 20px;
    overflow: hidden;
    li {
        font-family: $poppins;
        float: left;
        list-style: none;
        background: #D3EAFF;
        padding: 4px 14px;
        border-radius: 14px;
        font-size: 14px;
        line-height: 20px;
        color: #4D5052;
        margin: 0px 10px 10px 0px;
        .round_btn {
            color: #4D5052;
            font-size: 14px;
            margin-left: 5px;
            border-radius: 50%;
        }
        &:last-child {
            background: #1F70EA;
            color: #ffffff;
        }
    }
}


/*common css ends*/

.top_part {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 16px;
    .right_option {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .left_search_icon::after {
            font-family: "FontAwesome";
            content: "\f002";
            left: 12px;
            top: 10px;
            font-size: 18px;
            color: #dadada;
            position: absolute;
        }
        .search_part {
            font-family: $poppins;
            color: #696D70;
            border: 2px solid #E7EDF1;
            outline: none;
            width: 330px;
            margin-bottom: 0px;
            padding: 10px 16px 10px 35px;
            position: relative;
            border-radius: 4px;
        }
        .blue_btn {
            margin-left: 16px;
        }
    }
}

.draft_label {
    font-family: $poppins;
    background: #DEDEDE;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    color: #4D5052;
    margin: 0px 30px;
}

.approved_label {
    font-family: $poppins;
    background: #C1FFC4;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    color: #4D5052;
    margin: 0px 30px;
}

.edit_label {
    font-family: $poppins;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px !important;
    color: #000;
    margin-bottom: 0px !important;
}

.editor-label {
    font-family: $poppins;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px !important;
    color: #000;
}

.v-divider.theme--dark {
    border-color: #232e3e !important;
}


/*my code ends*/