.doc_body {
    margin: 24px;
    padding: 24px 32px 66px 32px;
    border: 2px solid #E7EDF1;
    border-radius: 0px 0px 4px 4px;
    h3 {
        font-family: $poppins;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        text-transform: capitalize;
        color: #303233;
        padding-bottom: 38px;
    }
}

.review {
    margin: 24px;
    padding: 24px 32px 66px 32px;
    border: 2px solid #E7EDF1;
    border-radius: 0px 0px 4px 4px;
    h3 {
        font-family: $poppins;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        text-transform: capitalize;
        color: #303233;
        padding-bottom: 38px;
        margin-bottom: 0px !important;
    }
    .review_list {
        h4 {
            font-family: $poppins;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.2px;
            color: #3B3D3F;
            padding-bottom: 4px;
            margin-bottom: 0px !important;
        }
        p {
            font-family: $poppins;
            font-size: 14px;
            line-height: 20px;
            color: #4D5052;
            padding-bottom: 24px;
            margin-bottom: 0px !important;
        }
        .cover {
            max-width: 130px;
            padding: 8px 0px;
        }
        a {
            color: #1F70EA !important;
            text-decoration: underline !important;
        }
    }
}


/*comment design starts*/

.comment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 18px;
    .left_part {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .right_part {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .badges {
            margin-right: 26px;
            i {
                color: #DADADA;
                font-size: 20px;
                position: relative;
            }
            .badge {
                position: absolute;
                top: 13px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
            }
            &.active i {
                color: #1F70EA !important;
            }
            &.active .badge {
                background: #DBE9FF;
                color: #1F70EA;
                width: 16px;
                height: 16px;
                border-radius: 50%;
            }
        }
    }
}


/*comment design ends*/

.data {
    padding: 20px;
    overflow-x: scroll;
    scrollbar-width: none;
    .table {
        thead {
            border: 2px solid #E7EDF1;
            th {
                font-family: $poppins;
                font-weight: bold;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.2px;
                text-transform: uppercase;
                color: #3B3D3F !important;
                padding: 14px 16px
            }
        }
        tbody {
            font-family: $poppins;
            font-size: 14px;
            line-height: 20px;
            color: #4D5052;
            border: 2px solid #E7EDF1;
            border-radius: 0px 0px 4px 4px;
            .pending,
            .draft,
            .approve,
            .overdue {
                padding: 4px 10px;
                background: #FFFBD3;
                border-radius: 4px;
                display: inline-block;
            }
            .draft {
                background: #DEDEDE;
            }
            .approve {
                background: #C1FFC4;
            }
            .overdue {
                background: #FFD3D3;
                color: #D33F3F;
            }
        }
    }
}


/*data-table ends*/

@media (max-width: 1000px) {
    .data {
        overflow-x: scroll;
        .table_cover {
            width: 1100px;
        }
    }
}

@media (max-width:767px) {
    body {
        padding-bottom: 180px;
    }
    .top_part {
        display: block;
        .right_option {
            .search_part {
                max-width: 100%;
                width: 100%;
            }
        }
    }
    .fixed_button {
        .button_group {
            button {
                margin-left: 0px;
                margin-bottom: 12px;
                display: block;
                width: 100%;
            }
        }
    }
}

@media (max-width:600px) {
    .top_part {
        .right_option {
            display: block;
            .search_part {
                margin-bottom: 25px;
            }
            .blue_btn {
                margin-left: 0px;
                display: block;
                text-align: center;
            }
        }
    }
}


/*responsive design ends*/